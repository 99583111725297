<template>
  <v-row class=" pb-2 patientDrawer" no-gutters>
    <v-date-picker
      range
      dense
      no-title
      :events="activityArray"
      event-color="primary"
      readonly
      type="date"
      show-adjacent-months
      width="270"
      style="margin-left: -12px;"
    >
    </v-date-picker>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  data: () => ({
    date: null,
    currentPeriodRange: [],
    currentRangeStartDate: null,
    currentRangeEndDate: null,
  }),
  watch: {
    // daysOfDataWatcher: {
    //   immediate: true,
    //   handler: 'translateDaysOfData',
    // },
  },
  computed: {
    ...mapState('metrics', ['daysOfDataDict', 'daysOfDataWatcher']),
    ...mapState('patient', ['patient']),
    activityArray() {
      let internalActivityArray = []
      if (this.patient && this.patient.rpm && this.patient.rpm.activity && this.patient.rpm.activity.dates) {
        this.patient.rpm.activity.dates.forEach(date => {
          internalActivityArray.push(date.substr(0, 10))
        })
      }
      return internalActivityArray
    },
  },
  methods: {
    getDates(startDate, stopDate) {
      var dateArray = new Array()
      var currentDate = startDate
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate).toISOString().substr(0, 10))
        currentDate.setDate(currentDate.getDate() + 1)
      }
      return dateArray
    },
    currentRPMPeriod(val) {
      return this.currentPeriodRange.includes(val)
    },
    translateDaysOfData() {
      let days = Object.keys(this.daysOfDataDict)
      days.sort()
      let internalActivityArray = []
      days.forEach(day => {
        // let items = this.daysOfDataDict[day]
        let date = new Date(parseInt(day)).toISOString().substr(0, 10)
        internalActivityArray.push(date)
      })
      this.activityArray = internalActivityArray
    },
  },
  mounted() {
    if (this.patient.rpm.enrollment.firstData) {
      // this tells me when the next 99454 is up

      let periodStart = new Date(this.patient.rpm.enrollment.firstData)
      periodStart.setHours(0, 0, 0, 0)

      let rightNow = new Date()

      let monitoringPeriods = []

      while (periodStart.getTime() < rightNow.getTime()) {
        // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
        let start = periodStart.getTime()
        periodStart.setDate(periodStart.getDate() + 30)
        let end = new Date(periodStart.getTime() - 1).getTime()
        monitoringPeriods.push({ start: start, end: end })
      }

      let startDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].start)
      let endDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].end)

      this.currentRangeStartDate = this.getShortDateFromTimestamp(startDateObject.getTime())
      this.currentRangeEndDate = this.getShortDateFromTimestamp(endDateObject.getTime())
      this.currentPeriodRange = this.getDates(startDateObject, endDateObject)

      // what if periods were 30 days from the start of the month?
      // what about feb?
    }
  },
}
</script>

<style lang="scss">
.patientDrawer {
  .v-date-picker-table {
    background-color: transparent !important;
  }
  .v-picker {
    background: transparent !important;
  }
  .v-picker__body--no-title {
    background: transparent !important;
  }
}
</style>
